import { nameSimplyMask } from '@tencent/oneid-utils';
import { AuthnApi as CommonAuthnApi } from 'common/api';
import {
  APP_EDITION_TYPE,
  AUTHENTICATION_METHOD,
  NOT_FOUND_ERROR,
  SUPER_ADMIN_ROLE_ID,
  TEMPLATE_MAP,
  TEMPLATES,
} from 'common/constants/Constants';
import { IAccount, IUserInfo } from 'common/constants/Types';
import CommonAuthnApiNew from 'common/http/services/AuthnApi';
import CommonAccountApiV3 from 'common/http/servicesV3/AccountApi';
import CommonRolesApiV3 from 'common/http/servicesV3/RolesApi';
import Utils from 'common/utils';
import { APP_REPORT_KEY, dataReport as beaconReport } from 'common/utils/dataReport';
import JSRuntime from 'common/utils/JSRuntime';
import { VendorApi } from 'idpBase/api';
import { AuthnApi } from 'idpBase/http';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { DEFAULT_SETTINGS, REDIRECT_WHITE_LIST } from './constant';

class RootStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<RootStore>;
  public resetStore: () => void;
  public annotationOverrides = {
    isJustBrandDemoPC: false,
  };
  /** 用户信息 */
  public userInfo = {
    accountUser: {},
    user: {},
    authenticationMethod: undefined,
    personStatus: '',
  } as IUserInfo;
  /** 企业信息 */
  public account = {} as IAccount;
  /** 用户所属角色列表 */
  public joinRoles: { role_id: string }[] = [];
  // 租户的配置信息，包含订阅的应用、企业信息，自定义外观等
  public settings = DEFAULT_SETTINGS; // 数据请求在 /login 登录后以及 /sso_login 登录前
  public hasFetchSettings = false; // 是否获取过 settings
  public accountId = ''; // 有登录态或者自定义域名或者私有化才有值，不是始终有值
  public externalAppInfo = {
    logoUrl: '',
    name: '',
    redirectUri: '',
    id: '',
    edition: APP_EDITION_TYPE.COMPANY,
  };
  public externalAppType = '';

  public prevUrl = document.referrer; // 路由切换的前一个路由，给埋点数据上报使用
  public currentUrl = window.location.href; // 当前路由

  /** 是否超级管理员 */
  get isSuperAdmin() {
    return this.joinRoles.some((role) => role.role_id === SUPER_ADMIN_ROLE_ID.ONEID);
  }

  /** 是否为管理员 */
  public get isAdmin() {
    return this.joinRoles.length > 0;
  }

  // admin系统外观参数,初始值
  public demoParams = {
    isFullScreen: !JSRuntime.isBrandDemoOrigin,
    isPc: !!JSRuntime.isBrandDemoOrigin,
  };

  public get isJustBrandDemoPC(): string {
    return (
      JSRuntime.isBrandDemoOrigin &&
      !m.get(this.demoParams, 'isFullScreen') &&
      m.get(this.demoParams, 'isPc')
    );
  }

  public get isDocsTemplate(): boolean {
    return m.get(this.settings, 'account.brands.template') === TEMPLATES.DOC;
  }

  public logout() {
    return CommonAuthnApi.logout(this.getIDPRedirectUrl());
  }

  private canRedirect = true;

  public matchDomain(domain: string) {
    const { host } = window.location;
    const configUrl = new URL(JSRuntime.originalIdpSite);
    let currentDomain = _.replace(host, configUrl.host, '');
    currentDomain = _.trimEnd(currentDomain, '.');
    return currentDomain === domain;
  }

  public async getUserInfo() {
    const res = await CommonAuthnApiNew.getSelfV3({ app: JSRuntime.backEndApp });
    const { errCode, data: userInfo } = res;

    this.dataReport('e#oneid_total#all#get_self');

    // 企业不存在
    if (errCode === NOT_FOUND_ERROR) {
      Utils.redirectToOriginalIdp();
      return;
    }

    // 其它的错误场景，如登录态过期
    if (errCode) {
      Utils.handleNoAuth();
      return;
    }

    window.localStorage.setItem('authenticationMethod', userInfo.authenticationMethod || '');

    this.updateStore({ userInfo });

    return userInfo;
  }

  /**
   * 获取企业信息
   */
  async getAccountInfo() {
    const account = await CommonAccountApiV3.getAccountInfo();
    this.updateStore({ account });
    return account;
  }

  /**
   * 获取用户所属角色列表
   */
  async getJoinRoles() {
    this.joinRoles = await CommonRolesApiV3.getJoinRoles();
    return this.joinRoles;
  }

  // 根据用户的登录方式来决定跳转的idp页面
  public redirectToIdpByUserMethod(...params: string[]) {
    Utils.safeRedirect(this.getIDPRedirectUrl(...params));
  }

  public getIDPRedirectUrl(qs?: string, path?: string): string {
    const method = window.localStorage.getItem('authenticationMethod');
    if (method === AUTHENTICATION_METHOD.ENTERPRISE) {
      return JSRuntime.getIdpSite(qs, path);
    }
    return JSRuntime.getOriginalIdpSite(qs, path);
  }

  // 获得第三方应用的配置信息
  public getExternalAppInfo(appType: string) {
    return VendorApi.getExternalAppInfoByType(appType)
      .then((res) => {
        this.updateStore({ externalAppInfo: res, externalAppType: appType });
        return res;
      })
      .catch((err) => {
        this.global.handleError(err);
      });
  }

  /**
   * 获得租户初始配置数据
   * @param params 企业id
   * @returns 初始配置数据
   */
  public async getSettings(params: { accountId: string }) {
    const settings = await AuthnApi.getSettings(params);
    if (settings.errCode) return;

    const brands = m.get(settings, 'account.brands');
    const template = m.get(settings, 'account.brands.template');
    const styleInfo = m.get(TEMPLATE_MAP, template);
    const curBrands = _.mergeWith(brands, styleInfo, (tar: string, src: string) => tar || src);
    _.set(settings, 'account.brands', curBrands);
    this.updateStore({
      settings,
      hasFetchSettings: true,
    });
    return settings;
  }

  public setSettingsBrands(brands: any) {
    mobx.set(this.settings.account, 'brands', brands);
  }
  /**
   * idp 登录侧所有登录成功后的跳转都走这里
   * (暂不处理个人版账号情况)
   * */
  public async redirectToLandingPage(options?: {
    accountId?: string;
    username?: string;
    skipSelect?: boolean; // 去开通会议/文档需要跳过选择账号
  }) {
    const opts = options || {};
    // TODO: 未开通文档应用，需要跳转到无权限页面

    // 1. 地址栏有回调地址 redirectUrl 则登录成功后优先跳转
    const redirectUrl = JSRuntime.thirdLandingPage;

    if (redirectUrl) {
      // 多次跳转时，触发鉴权跳转，会导致多次鉴权产生报错。
      // 这里逻辑会前往redirectUrl，返回该页面，状态会重置。暂不手动恢复该状态
      if (!this.canRedirect) return;
      this.canRedirect = false;
      const currUrl = new URL(window.location.href);
      const originUrl = new URL(decodeURIComponent(redirectUrl));
      // TIPS：白名单判断标准，要么是腾讯域名，要么是浏览器url的host等于redirectUrl的host（主要解决私有化情况）
      const isWhiteList =
        REDIRECT_WHITE_LIST.some((reg) => reg.test(originUrl.host)) ||
        currUrl.host === originUrl.host;
      // 不在白名单中不跳转 直接去portal
      if (!isWhiteList) return Utils.redirectToPortal();
      const url = JSRuntime.isPrivate
        ? redirectUrl
        : JSRuntime.getOAuth2RedirectUrl(redirectUrl, opts.username || 'username', true);
      const redirect = !!opts.skipSelect ? JSRuntime.getSubscribeUrl(url) : url;
      if (redirect) {
        Utils.safeRedirect(redirect);
        Utils.postPopupRedirectMsg(redirect);
      }
      return;
    }

    // 2. 仅有一个套件应用且仅有一个vendorType=KIT的sso应用那么会直接触发这个 sso 应用的登录而不是跳转到 portal 页
    let settings = this.settings;
    if (!this.hasFetchSettings) {
      let accountId = opts.accountId || this.account.accountId;
      if (!accountId) {
        // 没有传 accountId 的动态获取一下
        const account = await CommonAccountApiV3.getAccountInfo();
        accountId = account.accountId;
      }

      accountId && (settings = await this.getSettings({ accountId }));
    }

    const suiteApps = m.get(settings, 'account.apps');

    if (!m.isArray(suiteApps) || suiteApps.length !== 1) return Utils.redirectToPortal();

    const res = await AuthnApi.getSSOApps();

    if (res.errCode) {
      return Utils.redirectToPortal();
    }

    const ssoApps = m.get(res, 'apps');

    if (
      m.isArray(ssoApps) &&
      ssoApps.length === 1 &&
      m.get(ssoApps, '0.vendorType') === 'KIT' &&
      m.get(ssoApps, '0.idpInitiated')
    ) {
      const appId = m.get(ssoApps, '0.id');
      const redirectUrl = CommonAuthnApi.getLoginSSOLink(appId);
      return Utils.safeRedirect(redirectUrl);
    }

    return Utils.redirectToPortal();
  }

  // 仅有自定义域名及私有化环境可以通过如下方式获得 settings
  public async initSettings() {
    const domainName = window.location.hostname;

    let accountId = this.accountId;
    const hash = Utils.getPureHashPath();
    if (JSRuntime.isCustomDomain && (!accountId || hash.includes('#/sso_login/identity'))) {
      // 自定义域名通过当前域名获得 AccountId
      const res = await AuthnApi.loginByEmailOrDomain({ domainName });
      if (res.errCode) return;
      accountId = _.get(res, 'accountId');
    }

    if (!accountId) return;

    await this.getSettings({ accountId });
    this.updateStore({ accountId });
  }

  // 重置accountId、settings等信息
  public resetAccount() {
    this.updateStore({
      accountId: '',
      settings: DEFAULT_SETTINGS,
      hasFetchSettings: false,
    });
  }

  public updateDataReportUrl() {
    if (this.currentUrl === window.location.href) return;
    this.updateStore({
      prevUrl: this.currentUrl,
      currentUrl: window.location.href,
    });
  }

  // 数据上报
  public dataReport(eventName: string, params?: Record<string, any>) {
    const {
      accountUser: { aliasId, unionId },
    } = this.userInfo;
    const { accountId, accountType, name } = this.account;

    const { isSendBeacon, ...rest } = params || {};

    const reqParams = {
      alias_id: aliasId,
      union_id: unionId,
      account_uid: accountId,
      account_type: accountType,
      account_name: nameSimplyMask(name, { maskLength: 1 }),
      prev_url: this.prevUrl || document.referrer || JSRuntime.thirdLandingPage, // NOTICE 在无登录态时，手动输入管理后台/工作台地址，会跳转到登录相关页面，prev_url是当前路径上的redirectUrl
      app_name: JSRuntime.backEndApp || 'oneid',
    };
    return beaconReport(eventName, reqParams, rest, {
      [APP_REPORT_KEY.WEMEET]: JSRuntime.isTencentMeeting,
      isSendBeacon,
    });
  }

  // 信息加密
  public async encryptRequest(params: { subject?: string; password: string }) {
    const [encryptErr, encrypt] = await Utils.resolvePromise(CommonAuthnApi.getPwdEncryptKey());
    if (encryptErr) return '';
    return Utils.encryptObj(encrypt.key, encrypt.kid, params);
  }
}

export default RootStore;
