import { AxiosInstancePlus } from '@tencent/oneid-request';
import { IResponseType } from 'common/constants/Types';
import { http } from 'common/http';

class AccountApi {
  public axiosInstance: AxiosInstancePlus = http;

  /**
   *
   * @param  code 由腾讯云返回
   * @param state oneid生成并返回
   * @returns applicant_info_lost 身份信息缺失则需要申请人填写身份信息
   */
  getAuthorizeResult(params: {
    code: string;
    state: string;
  }): Promise<
    IResponseType<{ applicant_info_lost: boolean; enterprise_name: string; social_code: string }>
  > {
    return this.axiosInstance.post('admin/v2/account/certify/tencentcloud', params, {
      skipShowError: true,
    });
  }
}
export default new AccountApi();
