import { AxiosInstancePlus, CancelApi, RequestConfig } from '@tencent/oneid-request';
import { IResponseType } from 'common/constants/Types';
import { http } from 'common/http';
import JSRuntime from 'common/utils/JSRuntime';
import {
  AUTH_TYPE,
  IFaceAuthInfo,
  IFaceAuthPolling,
  IImgOcrInfo,
  SMS_CODE_TYPE,
} from 'idpBase/Constants';

class AccountApi {
  public axiosInstance: AxiosInstancePlus = http;

  public prefixURL = '/person/v1';

  /**
   * 获取个人认证信息
   */
  getAuthInfo(authType: AUTH_TYPE) {
    return this.axiosInstance.get(`${this.prefixURL}/account/personal/certify/get`, {
      params: { certify_type: authType, app_type: JSRuntime.appFromUrl },
    });
  }

  /**
   * 认证信息授权
   * @param params
   * @returns
   */
  authorize() {
    return this.axiosInstance.put(`${this.prefixURL}/account/personal/certify/authorize`, {
      app_type: JSRuntime.appFromUrl,
    });
  }

  /**
   * 同步认证信息到个人中心
   * @param params
   * @returns
   */
  syncAuthInfo() {
    return this.axiosInstance.put(`${this.prefixURL}/account/personal/certify/profile/sync`);
  }

  /** ORC */
  getOcr(cosId: string): Promise<IImgOcrInfo> {
    return this.axiosInstance.post(
      `${this.prefixURL}/account/personal/certify/get_img_info_by_ocr`,
      { cos_id: cosId },
    );
  }

  /**
   * 获取手机验证码
   * @param params
   * @returns
   */
  sendSmsCode(
    sms_code_type: SMS_CODE_TYPE,
    certify_type: AUTH_TYPE,
    job_certify_id: string,
  ): Promise<{ verify_session_id: string; expire: number }> {
    return this.axiosInstance.post(`${this.prefixURL}/account/personal/certify/send_sms_code`, {
      sms_code_type,
      job_certify_id,
      certify_type,
    });
  }

  /**
   * 企业认证发送短信验证码
   * @param phone 手机号
   * @returns
   */
  sendSmsCodeWithPhone(
    phone: string,
  ): Promise<IResponseType<{ verification_session_id: string; expire: number }>> {
    return this.axiosInstance.post(
      '/person/v1/account/personal/certify/personal_account_job_certify_info_send_sms_code',
      { phone },
    );
  }
  /**
   * 进行头像的安全审核
   * @param params
   * @returns
   */
  sendForLogoSafe(params: { url: string }): Promise<{ url: string }> {
    return this.axiosInstance.post(
      `${this.prefixURL}/account/personal/certify/deal_file_safe`,
      params,
    );
  }

  /**
   * 获取人脸识别token和url信息
   * @param params
   * @returns
   */
  getFaceAuthInfo(params: Record<string, any>): Promise<IFaceAuthInfo> {
    return this.axiosInstance.post(
      `${this.prefixURL}/account/personal/certify/face_auth/get_face_auth_info`,
      params,
    );
  }

  /**
   * 获取人脸识别结果
   * @param params
   * @returns
   */
  getFaceCheckResult(
    params: Record<string, any>,
    options?: RequestConfig<IFaceAuthPolling>,
  ): Promise<CancelApi> {
    return this.axiosInstance.post(
      `${this.prefixURL}/account/personal/certify/face_auth/get_detect_info`,
      params,
      options,
    );
  }

  /**
   * 保存个人租户用户认证信息
   * @param params
   * @returns
   */
  saveAuthInfo(params: Record<string, any>): Promise<{ job_certify_id: string }> {
    return this.axiosInstance.post(`${this.prefixURL}/account/personal/certify/save`, params);
  }

  /**
   * 撤回个人租户用户认证
   * @param jobId 认证id
   * @returns
   */
  revokeAuth(jobId: string) {
    return this.axiosInstance.put(
      `${this.prefixURL}/account/personal/certify/revoke_personal_account_job_certify`,
      {
        job_certify_id: jobId,
      },
    );
  }
}
export default new AccountApi();
